import { gql } from '@apollo/client';

export const OFFICE_REFERENCES_QUERY = gql`
  query GetReference($input: GetReferencesInput!) {
    getReferences(input: $input) {
      total
      list {
        id
        name
        address
        text
        created
      }
    }
  }
`;
