import * as React from 'react';
import styled from 'styled-components';
import { useLocation, useRouteMatch } from 'react-router-dom';
import { animated } from 'react-spring';

import { useQuery } from '@apollo/client';

import { Seo } from '../../../../utils/seo/Index';
import { Container } from '../../../layout/Container';
import { Section } from '../../../layout/Section';
import { Heading } from '../../../ui/text/Heading';

import { ReferencesSection } from './ReferencesSection';
import { FormSection } from './FormSection';
import NoMatch from '../../404Old';
import { useFadeTransition } from '../../../../utils/hooks/useFadeTransition';
import { OFFICE_HAS_REFERENCES_QUERY } from '../../../../../client/__graphql__/queries/office/references/hasReferences';
import { OFFICE_ABOUT_REFERENCES_QUERY } from '../../../../../client/__graphql__/queries/office/references/about';

const References: React.FC = () => {
  const match = useRouteMatch<{ urltag: string }>();
  const location = useLocation();

  const {
    data: office,
    loading,
    error
  } = useQuery(OFFICE_HAS_REFERENCES_QUERY, {
    fetchPolicy: 'network-only',
    variables: {
      input: {
        urltag: match?.params?.urltag
      }
    }
  });

  const { fadeIn } = useFadeTransition({
    loaded: !loading
  });

  const { data } = useQuery(OFFICE_ABOUT_REFERENCES_QUERY, {
    fetchPolicy: 'network-only',
    variables: {
      input: {
        urltag: match?.params?.urltag
      }
    },
    onError: ({ graphQLErrors }) => {
      graphQLErrors?.map((item: any, index: number) => {});
    }
  });

  if (office?.getOffice?.hasReferences === false) {
    return <NoMatch />;
  }

  if (error?.graphQLErrors?.length) {
    if (error?.graphQLErrors[0]?.extensions?.code === '404') {
      return <NoMatch />;
    }
  }

  return (
    <animated.div style={fadeIn}>
      <Seo
        title={`PrivatMegleren - Eiendomsmegler ${data?.office?.name} - Referanser`}
        description="Se referanser fra fornøyde kunder"
        image={
          data?.office?.seo?.image
            ? data.office.seo.image
            : 'https://cdn.reeltime.no/pm_assets/img/bakgrunner/pmfront.jpg'
        }
        imageTitle="/PrivatMegleren-eiendomsmegler-kjøp-salg-eiendom-leilighet-hus-bolig"
        url={location.pathname}
      />
      <Container style={{ paddingTop: '150px' }}>
        <Section className="center">
          {data?.office?.referencesText ? (
            <TopText
              dangerouslySetInnerHTML={{ __html: data.office.referencesText }}
            />
          ) : (
            <>
              <Heading
                tag="h1"
                center={true}
              >{`PrivatMegleren ${data?.office?.name}`}</Heading>
              <Heading tag="h2" center={true}>
                Eiendomsmegler {data?.office?.municipality}
              </Heading>
              <StyledTopContent
                dangerouslySetInnerHTML={{ __html: data?.office?.about }}
              />
            </>
          )}
        </Section>
      </Container>
      <ReferencesSection />
      <FormSection text={data?.office?.referencesFormText} />
    </animated.div>
  );
};

export default References;

const TopText = styled.div`
  text-align: center;
`;

const StyledTopContent = styled.div`
  text-align: center;

  h1 {
    font-size: 25px;
  }
`;
