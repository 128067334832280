import * as React from 'react';
import styled from 'styled-components';

interface IReference {
  text: string;
  name: string;
  address: string;
}

export const ReferenceCard: React.FC<IReference> = ({
  text,
  name,
  address,
}) => {
  return (
    <StyledReference>
      <p dangerouslySetInnerHTML={{ __html: text }} />
      <div className="reference-footer">
        <span className="name">— {name}</span>
        {address ? <span className="address">{address}</span> : null}
      </div>
    </StyledReference>
  );
};

const StyledReference = styled.div`
  width: 100%;
  padding: 30px;
  background: ${({ theme }) => theme.colors.burgundy_card};
  text-align: center;

  .reference-footer {
    margin-top: 20px;

    .name {
      display: block;
      color: ${({ theme }) => theme.colors.primary};
    }
    .address {
      display: block;
      color: rgb(255, 255, 255);
    }
  }
`;
