import * as React from 'react';
import { useRouteMatch } from 'react-router';
import styled from 'styled-components';

import { useQuery } from '@apollo/client';

import { Heading } from '../../../ui/text/Heading';
import { ReferenceCard } from '../../../ui/cards/Reference';
import { Pagination } from '../../../ui/pagination/Pagination';
import { OFFICE_REFERENCES_QUERY } from '../../../../../client/__graphql__/queries/office/references/getReferences';

export const ReferencesSection: React.FC = () => {
  const match = useRouteMatch<{ urltag: string }>();
  const [page, setPage] = React.useState<number>(0);

  const bergen = [
    'bergensentrum',
    'vest',
    'nord',
    'nyeboligerbergen',
    'nordhordland'
  ];
  const includes = bergen.includes(String(match?.params.urltag));

  const { data, loading } = useQuery(OFFICE_REFERENCES_QUERY, {
    fetchPolicy: 'network-only',
    variables: {
      input: {
        type: 'OFFICE',
        urltag: includes ? 'bergensentrum' : match?.params.urltag,
        pagination: { limit: 3, page: page }
      }
    }
  });

  return (
    <StyledReferencesSection>
      <div className="container">
        <Heading tag="h2" center={true}>
          Referanser
        </Heading>
        <ReferencesGrid>
          {data?.getReferences?.list?.length > 0 ? (
            data?.getReferences?.list?.map((item: any, index: number) => {
              return (
                <ReferenceCard
                  key={`reference_${item.id}`}
                  text={item.text}
                  name={item.name}
                  address={item.address}
                />
              );
            })
          ) : !loading ? (
            <p style={{ textAlign: 'center' }}>Ingen referanser funnet.</p>
          ) : null}
        </ReferencesGrid>
      </div>
      {data?.getReferences?.list?.length ? (
        <PaginationWrapper>
          <Pagination
            center={true}
            currentPage={page}
            maxPages={data?.getReferences?.total}
            total={data?.getReferences?.total}
            itemsPerPage={3}
            pageOnChange={({ selected }) => {
              setPage(selected);
            }}
          />
        </PaginationWrapper>
      ) : null}
    </StyledReferencesSection>
  );
};

const PaginationWrapper = styled.div`
  width: 100%;
  padding: 0 30px;
`;

const StyledReferencesSection = styled.section`
  padding: 100px 0;
  width: 100%;
  background: ${({ theme }) => theme.colors.burgundy};

  .container {
    padding: 0 30px;
    max-width: 960px;
    margin: 0 auto;
  }
`;

const ReferencesGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 20px;
  width: 100%;
`;
